import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import GoogleMaps from '../../components/R8tr/GoogleMap/GoogleMap';
import R8trJobs from '../../components/R8tr/R8trJobs/R8trJobs';
import { UserDashboardStatAction } from '../../redux/actions/R8tr/UserDashboardStatAction';
import { getUserData } from '../../redux/actions/UserAction';
import ProfileClickedContext from './../../utils/context';
import { useStyles } from './styles';

function Dashboard({ showCurrentJobs }) {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [showDashboard, setShowDashboard] = useState(false);
  const classes = useStyles();
  const { userDetails } = useSelector((state) => state.UserReducer);
  const profileClickedContext = useContext(ProfileClickedContext);
  profileClickedContext.profileClickedHandler(false);

  useEffect(() => {
    dispatch(getUserData());
    dispatch(UserDashboardStatAction());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('userRole') == 'r8tr') {
      if (userDetails?.data?.user?.phoneNumber) {
        if (
          userDetails.data?.user?.firstName == null ||
          userDetails.data?.user?.firstName == '' ||
          userDetails.data?.user?.email == null ||
          userDetails.data?.user?.email == ''
        ) {
          navigator('/personal-info');
        } else if (userDetails.data?.areas?.length < 1) {
          navigator('/service-area');
        } else if (userDetails.data?.levelOfExpertise?.length < 1) {
          navigator('/area-expertise');
        } else {
          setShowDashboard(true);
        }
      }
    } else {
      setShowDashboard(true);
    }
  }, [userDetails]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.container}
      >
        {showDashboard && (
          <>
            <GoogleMaps
              // locations={locations}
              showCurrentJobs={showCurrentJobs}
            />

            <R8trJobs showCurrentJobs={showCurrentJobs} />
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default Dashboard;
