import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import Layout from '../components/SideNav/layout/Layout';

const ProtectedRoute = ({ socket }) => {
  const tokens = JSON.parse(localStorage.getItem('tokens'));
  const { isLoggedIn } = useSelector((state) => state.loginSlice);

  return isLoggedIn && tokens?.accessToken ? (
    <Layout socket={socket}>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
