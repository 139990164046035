import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Signup from 'components/Signup';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import BuyerRatingR8tr from '../components/Buyer/BuyerRatingR8tr/BuyerRatingR8tr';
import HireR8tr from '../components/Buyer/HireR8tr/HireR8tr';
import PdfPage from '../components/Buyer/PdfPage/PdfPage';
import RatingDetail from '../components/Buyer/RatingDetail/RatingDetail';
import Notes from '../components/Buyer/SpecialNotes/SpecialNotes';
import SubmitPayment from '../components/Buyer/SubmitPayment/SubmitPayment';
import TipPayment from '../components/Buyer/TipPayment/TipPayment';
import UpdateHireR8tr from '../components/Buyer/UpdateHireR8tr/HireR8tr';
import UploadedVideos from '../components/Buyer/UploadedVideos/UploadedVideos';
import SingleJobDetailBuyer from '../components/BuyerJobs/CurrentJobDetailBuyer/CurrentJobDetailBuyer';
import ForgetPassword from '../components/ForgotPassword/index';
import GetOtp from '../components/GetOtp';
import Login from '../components/Login';
import AreaOfExpertise from '../components/Profile/AreaOfExpertise/AreaOfExpertise';
import LevelOfExpertise from '../components/Profile/LevelOfExpertise/LevelOfExpertise';
import ServiceArea from '../components/Profile/ServiceArea/ServiceArea';
import Notification from '../components/R8tr/Notification/Notification';
import R8ting from '../components/R8tr/R8ting/R8ting';
// import PastRating from "../components/Buyer/PastRating/PastRating";
import R8trRating from '../components/R8tr/R8trRating/R8trRating';
import SingleJobDetail from '../components/R8tr/Singlejobdetail/SingleJobDetail';
import SpecialNotes from '../components/R8tr/SpecialNotes/SpecialNotes';
import VideoUpload from '../components/R8tr/VideoUpload/VideoUpload';
import ResetPassword from '../components/ResetPassword';
import Support from '../components/Support/Support';
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions';
import Braintree from '../pages/Braintree';
import Home from '../pages/Dashboard';
import {
  default as CompleteProfile,
  default as ProfileInformation,
} from '../pages/Profile/index';
import ProtectedRoute from './protectedRoute';
import PublicRoutes from './publicRoute';

const generateClassName = createGenerateClassName({
  seed: 'App1',
});

function AppRoutes({ socket }) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Router basename="/">
        <Grid container>
          <CssBaseline />
          <Routes>
            <Route
              exact
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route exact path="/" element={<ProtectedRoute socket={socket} />}>
              <Route
                exact
                path="/ebay-hire-a-r8tr"
                element={<UpdateHireR8tr />}
              />
              <Route exact path="/upload-videos" element={<VideoUpload />} />
              <Route
                exact
                path="/buyer-rating-r8tr"
                element={<BuyerRatingR8tr />}
              />
              <Route exact path="/r8ting" element={<R8ting />} />
              <Route exact path="/service-area" element={<ServiceArea />} />
              <Route exact path="/special-notes" element={<SpecialNotes />} />
              <Route exact path="/notes" element={<Notes />} />
              <Route exact path="/r8tr-rating" element={<R8trRating />} />
              <Route exact path="/rating-detail" element={<RatingDetail />} />
              <Route exact path="/submit-payment" element={<SubmitPayment />} />
              <Route exact path="/support" element={<Support />} />
              <Route exact path="/tip-payment" element={<TipPayment />} />
              <Route exact path="/pdf" element={<PdfPage />} />
              <Route
                exact
                path="/uploaded-videos"
                element={<UploadedVideos />}
              />
              <Route
                exact
                path="/area-expertise"
                element={<AreaOfExpertise />}
              />
              <Route
                exact
                path="/level-expertise"
                element={<LevelOfExpertise />}
              />
              <Route exact path="/payment-methods" element={<Braintree />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/:id" element={<Home />} />
              <Route
                exact
                path="/current-jobs"
                element={<Home showCurrentJobs={true} />}
              />
              <Route exact path="/detail" element={<SingleJobDetail />} />
              <Route
                exact
                path="/job-detail"
                element={<SingleJobDetailBuyer />}
              />
              <Route
                exact
                path="/personal-info"
                element={<ProfileInformation />}
              />
              <Route exact path="/notification" element={<Notification />} />
              <Route exact path="/hire-a-r8tr" element={<HireR8tr />} />
            </Route>
            <Route exact path="/" element={<PublicRoutes />}>
              <Route exact path="/signup" element={<Signup />} />

              <Route
                exact
                path="/complete-profile"
                element={<CompleteProfile />}
              />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/set-password" element={<ForgetPassword />} />
              <Route exact path="/get-otp" element={<GetOtp />} />
            </Route>
          </Routes>
        </Grid>
      </Router>
    </StylesProvider>
  );
}

export default AppRoutes;
